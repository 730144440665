<script lang="ts" context="module">import marked from "marked";
import hljs from "highlight.js";
import DOMPurify from "dompurify";
export class ParsingMD {
    static getNoteDom() {
        const mainNote = document.getElementById("main_note");
        if (!mainNote)
            throw new Error("mainNote is null");
        this.mainNote = mainNote;
        const previewNote = document.getElementById("preview_note");
        if (!previewNote)
            throw new Error("previewNote is null");
        this.previewNote = previewNote;
        const returnedArray = [mainNote, previewNote];
        this.typeArray = returnedArray;
    }
    static parseElement() {
        marked.setOptions({
            langPrefix: "",
            highlight: (code, language) => {
                const validLanguage = hljs.getLanguage(language) ? language : "plaintext";
                return hljs.highlight(validLanguage, code).value;
            },
            gfm: true,
            breaks: true,
            mangle: true,
        });
        const originalText = this.mainNote.value;
        const unsanitizedDirtyElement = marked(originalText);
        const sanitizedElement = DOMPurify.sanitize(unsanitizedDirtyElement);
        this.previewNote.innerHTML = sanitizedElement;
    }
    static changeAppearance(clickedNum) {
        const clickedElement = this.typeArray[clickedNum];
        // クリックされたボタンの配列インデックスが1だった場合、他方のボタンは 1 - 1 = 0 になる
        // 逆にクリックされたのが0だった場合、他方は 1 - 0 = 1 になる
        // なので 1 - クリックされたボタンのインデックス で他方のボタンを判定できる
        const anotherElement = this.typeArray[1 - clickedNum];
        // クリックされた方のz-indexを3に、そうでない方を2にする
        clickedElement.style.zIndex = "3";
        anotherElement.style.zIndex = "2";
    }
}
</script>

<textarea class="main-note center-content syncscroll" name="sameScroll" id="main_note"></textarea>
<div class="preview-note center-content syncscroll" name="sameScroll" id="preview_note"></div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.center-content {
  width: calc(80vh * 0.7692307692);
  max-width: 55vw;
  height: 80vh;
  position: absolute;
  top: calc( 50% - 80vh / 2 - 5vh );
  left: calc( 50% - calc(80vh * 0.7692307692) / 2 - 5vh );
  background: #fafafa;
  font-family: "inter", "nsjp", arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  resize: none;
  padding: 5vh;
  word-wrap: break-word;
  white-space: normal;
  overflow-y: scroll;
  line-height: 1.8;
}

.main-note {
  z-index: 3;
}

.preview-note {
  z-index: 2;
  line-height: 1.9;
}

@media (max-aspect-ratio: 1/1) {
  .center-content {
    top: 0;
    left: calc(50% - 90vw / 2);
    width: calc(90vw - 2vh* 2);
    max-width: calc(90vw - 2vh* 2);
    height: calc(100% - 2vh* 2);
    max-height: calc(100vh - 96px - 25vh - 2vh* 2);
    padding: 2vh;
    border-radius: 10px;
  }
}
@media screen and (max-width: 700px) and (max-aspect-ratio: 4/7) {
  .center-content {
    height: calc(100vh - 96px - 25vh - 2vh* 2 - 4vh);
    min-height: calc(100% - 2vh* 2);
  }
}</style>
