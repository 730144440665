<script lang="ts">import Rocket from "./svgComponents/Rocket.svelte";
</script>

<div class="coming-soon">
  <div class="coming-soon-sentence">Coming soon...</div>
  <div>
    <Rocket />
  </div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.coming-soon {
  margin: 0 auto;
  text-align: center;
}

.coming-soon-sentence {
  padding: 5% 0;
  font-weight: bold;
  font-size: 20px;
  color: #66d17f;
}

@media screen and (max-width: 1200px) {
  .coming-soon-sentence {
    padding: 2vh 0;
    font-size: 15px;
  }
}</style>
