<script lang="ts">import ComingSoon from "../components/ComingSoon.svelte";
import CountGroup from "../components/CountGroup.svelte";
import HeaderLogo from "../components/svgComponents/HeaderLogo.svelte";
import CenterNote, { ParsingMD } from "../components/CenterNote.svelte";
import ProfileStuff from "../components/ProfileStuff.svelte";
import SideCard from "../components/SideCard.svelte";
import FileButtonGroup from "../components/FileButtonGroup.svelte";
import EditorButtonGroup from "../components/EditorButtonGroup.svelte";
import Copyright from "../components/Copyright.svelte";
import firebase from "firebase/app";
import "firebase/auth";
import { push } from "svelte-spa-router";
import { getDbRoot, insertBody } from "../utils/dbUtils.svelte";
import Toast, { fireToast } from "../components/Toast.svelte";
import { getBrowser } from "../utils/checkOS.svelte";
import { onMount } from "svelte";
import { getEditorPreviewDOM } from "../utils/getEditorPreviewDom.svelte";
const isLandScape = window.innerWidth > window.innerHeight;
const cardWidth = isLandScape ? "20vw" : "80vw";
const cardHeight = isLandScape ? "60vh" : "15vh";
firebase.auth().onAuthStateChanged((user) => {
    if (!user)
        return push("/signin");
    sessionStorage.setItem("uid", user.uid);
    console.log("--- Firebase user is detected ---");
});
let titleValue = "無題のノート";
onMount(async () => {
    console.log("--- DOM contents are loaded ---");
    getEditorPreviewDOM.initialize();
    ParsingMD.getNoteDom();
    const dbRoot = await getDbRoot();
    if (!dbRoot.current)
        return;
    const currentNote = dbRoot.current.data().current;
    if (!currentNote)
        return;
    titleValue = dbRoot.current.data()[currentNote].title;
    const currentNoteBody = dbRoot.current.data()[currentNote].body;
    insertBody(currentNoteBody);
    alertForFirefox();
});
const alertForFirefox = () => {
    const isFirefox = getBrowser() === "Firefox";
    if (!isFirefox)
        return;
    fireToast("お使いのブラウザではエディターがうまく機能しない可能性があります", "message");
};
const backSideCard = () => {
    const toolCard = document.getElementById("tool_card");
    if (!toolCard)
        throw new Error("Tool card doesn't exist");
    toolCard.classList.remove("visible");
    const toBeInserted = document.getElementById("to_be_inserted");
    if (!toBeInserted)
        throw new Error("toBeInserted div doesn't exist");
    toBeInserted.textContent = "";
};
const toggleRightCard = () => {
    const rightToolCard = document.getElementsByClassName("right-card")[0];
    if (!rightToolCard)
        throw new Error("rightToolCard doesn't exist");
    const rightCard = document.getElementsByClassName("right-card")[1];
    if (!rightCard)
        throw new Error("rightCard doesn't exist");
    rightToolCard.classList.toggle("right-card-visible");
    rightCard.classList.toggle("right-card-visible");
};
</script>

<HeaderLogo />
{#if !isLandScape}
  <div class="humburger-container">
    <button class="humburger-button" on:click="{toggleRightCard}"><i class="fas fa-sliders-h"></i></button>
  </div>
{/if}
<div class="toast-container">
  <Toast />
</div>
<div class="tool-card right-card side-card" id="tool_card">
  <SideCard width="{cardWidth}">
    <div slot="content">
      <button class="backward-button" id="backward_button" on:click="{backSideCard}"><i
          class="fas fa-angle-left"
        ></i></button>
      <div id="to_be_inserted"></div>
    </div>
  </SideCard>
</div>
<div class="right-card side-card">
  <SideCard width="{cardWidth}">
    <div slot="content">
      <ProfileStuff bind:titleValue />
      <FileButtonGroup />
      <Copyright />
    </div>
  </SideCard>
</div>
<div class="note-container">
  <CenterNote />
</div>
<div class="left-card side-card">
  <SideCard id="left_card" width="{cardWidth}" height="{cardHeight}">
    <div class="content" slot="content">
      <EditorButtonGroup />
      {#if isLandScape}
        <CountGroup />
        <ComingSoon />
      {/if}
    </div>
  </SideCard>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
#to_be_inserted {
  margin: 0 auto;
  text-align: center;
}

.humburger-container {
  position: fixed;
  top: 2.5vh;
  right: 3vh;
}
.humburger-container .humburger-button {
  color: #66d17f;
  font-size: 23px;
}

.side-card {
  position: fixed;
  top: calc( 50% - 60vh / 2 );
}

.left-card {
  left: 2vw;
}

.tool-card {
  visibility: hidden;
  opacity: 0;
  transition: 0.1s;
}
.tool-card .backward-button {
  color: #66d17f;
  font-size: 25px;
  margin: 10px;
  padding: 5px;
  width: 50px;
  border-radius: 10px;
  transition: 0.1s;
}
.tool-card .backward-button i {
  cursor: pointer;
}
.tool-card .backward-button:hover {
  background: #f5f5f5;
  transition: 0.1s;
}

.right-card {
  z-index: 5;
  right: 2vw;
  transition: 0.3s;
}

#to_be_inserted {
  height: calc(60vh - 90px);
  overflow-y: scroll;
}

@media (max-aspect-ratio: 1/1) {
  .left-card {
    position: relative;
    height: 15vh;
    padding: 3vh 0 1vh 0;
    display: flex;
    justify-content: center;
    left: auto;
    top: auto;
  }
  .left-card .content {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 15vh;
  }

  .right-card {
    top: 100px;
    right: -80vw;
  }

  .note-container {
    position: relative;
    width: 100vw;
    height: calc(100vh - 76px - 25vh);
  }
}</style>
