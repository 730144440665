<script lang="ts">export let title;
export let icon;
export let sentence;
const breaked = sentence.split("<br />");
</script>

<div class="privacy-policy-contents">
  <div class="section">
    <h2><i class="fas fa-{icon}"></i>{title}</h2>
  </div>
  <div class="words">
    {#each breaked as words, i}<span>{words}</span><br />{/each}
  </div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.privacy-policy-contents {
  text-align: left;
  margin: 0 auto;
  margin-top: 30px;
  width: 65vw;
  max-width: 900px;
  padding: 10px 0 40px 0;
  background: #fafafa;
  box-shadow: 2px 2px 5px #ececec, -2px -2px 5px #f5f5f5;
  border-radius: 17px;
  color: #333333;
}
.privacy-policy-contents .section {
  padding: 5px 20px;
  color: #66d17f;
}
.privacy-policy-contents .section h2 {
  font-size: 25px;
  color: #333333;
}
.privacy-policy-contents .section h2 i {
  font-size: 40px;
  color: #66d17f;
  padding-right: 10px;
}
.privacy-policy-contents .words {
  padding: 0px 25px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .privacy-policy-contents {
    width: 85vw;
    max-width: 85vw;
  }
  .privacy-policy-contents .section {
    padding: 5px 15px;
  }
  .privacy-policy-contents .section h2 {
    font-size: 18px;
  }
  .privacy-policy-contents .section h2 i {
    font-size: 22px;
    padding-right: 5px;
  }
  .privacy-policy-contents .words {
    font-size: 15px;
  }
}</style>
