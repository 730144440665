<script lang="ts">import Google from "./svgComponents/Google.svelte";
import GitHub from "./svgComponents/GitHub.svelte";
export let id;
export let iconToImport;
export let title;
export let onclick;
</script>

<button class="auth-button {iconToImport}" id="{id}" on:click="{onclick}">
  <span class="auth-button-icon">
    {#if iconToImport === 'google'}
      <Google />
    {:else if iconToImport === 'github'}
      <GitHub />
    {/if}
  </span>
  <span class="auth-button-title">{title}</span>
</button>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.auth-button {
  margin: 0 auto;
  text-align: center;
}

.auth-button {
  display: flex;
  width: 200px;
  height: 50px;
  background: #fafafa;
  box-shadow: 2px 2px 5px #ececec, -2px -2px 5px #f5f5f5;
  border-radius: 2px;
  transition: 0.2s;
}
.auth-button-icon, .auth-button-title {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  height: 100%;
}
.auth-button-icon {
  width: 40px;
}
.auth-button-title {
  width: 160px;
  font-family: "inter";
  font-weight: 700;
  font-size: 15px;
  color: #000000;
  opacity: 0.54;
}
.auth-button:hover {
  box-shadow: 1px 1px 3px #ececec, -1px -1px 3px #f5f5f5;
  transition: 0.2s;
}</style>
