<script lang="ts">import HeaderLogo from "../components/svgComponents/HeaderLogo.svelte";
import HelpFolder from "../components/svgComponents/HelpFolder.svelte";
import HelpNotebook from "../components/svgComponents/HelpNotebook.svelte";
</script>

<div class="help-body">
  <div class="help-top-title">
    <h1>SLOUCHを使いこなそう</h1>
  </div>
  <div class="help-section help-section-green">
    <div class="help-section-text">
      <h1>ノートを書く</h1>
      <p>SLOUCHでは、ログイン後すぐにノートを書き始めることができます。</p>
    </div>
    <div class="help-section-img">
      <HelpNotebook />
    </div>
  </div>
  <div class="help-section help-section-white">
    <div class="help-section-text">
      <h1>ノートを書く</h1>
      <p>SLOUCHでは、ログイン後すぐにノートを書き始めることができます。</p>
    </div>
    <div class="help-section-img">
      <HelpFolder />
    </div>
  </div>
  <div class="help-section help-section-green">
    <div class="help-section-text">
      <h1>ノートを書く</h1>
      <p>SLOUCHでは、ログイン後すぐにノートを書き始めることができます。</p>
    </div>
    <div class="help-section-img">
      <HelpNotebook />
    </div>
  </div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.help-body .help-top-title {
  margin: 0 auto;
  text-align: center;
}

.help-body {
  background: #66d17f;
  height: 100%;
}
.help-body .help-top-title {
  margin: 0;
  padding: 50px 0px 10px 0px;
}
.help-body .help-top-title h1 {
  color: #fafafa;
  font-family: "dosis", "corp";
  font-size: 50px;
}
.help-body .help-section {
  margin: 0;
  width: 100vw;
  min-height: 40vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 25%);
  overflow: visible;
}
.help-body .help-section-text {
  padding: 20px;
  text-align: left;
}
.help-body .help-section-text h1 {
  color: #fafafa;
  font-family: "corp";
  font-size: 40px;
  line-height: 1.8;
  border-bottom: 2px #ececec solid;
}
.help-body .help-section-text p {
  font-family: "nsjp";
  font-weight: bold;
  color: #46585c;
}
.help-body .help-section-img {
  padding: 30px 20px;
}
.help-body .help-section-green {
  background: #66d17f;
}
.help-body .help-section-white {
  background: #f5f5f5;
}
.help-body .help-section-white h1 {
  color: #66d17f;
  border-bottom: 2px #66d17f solid;
}</style>
