<script lang="ts">import firebase from "firebase/app";
import "firebase/auth";
import { push, link } from "svelte-spa-router";
import AuthButton from "../components/AuthButton.svelte";
import HeaderLogo from "../components/svgComponents/HeaderLogo.svelte";
import SignInStories from "../components/svgComponents/SigninStories.svelte";
import { onMount } from "svelte";
const providerGithub = new firebase.auth.GithubAuthProvider();
const providerGoogle = new firebase.auth.GoogleAuthProvider();
const checkFirebaseStatus = () => {
    firebase.auth().onAuthStateChanged((user) => {
        if (!user)
            return;
        console.log("Success");
        push("/");
    });
};
const signInWithGitHub = () => {
    firebase.auth().signInWithRedirect(providerGithub);
};
const signInWithGoogle = () => {
    firebase.auth().signInWithRedirect(providerGoogle);
};
onMount(() => {
    checkFirebaseStatus();
});
</script>

<HeaderLogo />
<div class="screen-division-container">
  <div class="left-container screen-division">
    <div class="text-editor-for-memorizing">ようこそ。</div>
    <div class="auth-button-container">
      <div>
        <AuthButton id="in_google" iconToImport="google" title="Sign in with Google" onclick="{signInWithGoogle}" />
      </div>
      <div>
        <AuthButton id="in_github" iconToImport="github" title="Sign in with Github" onclick="{signInWithGitHub}" />
      </div>
      <div><a href="/privacy-policy" use:link>プライバシーポリシー</a></div>
      <div class="term-container"><a href="/term-of-use" use:link>利用規約</a></div>
    </div>
  </div>
  <div class="right-container screen-division">
    <div>
      <SignInStories />
    </div>
  </div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.screen-division-container .left-container .auth-button-container div, .screen-division-container .left-container .text-editor-for-memorizing {
  margin: 0 auto;
  text-align: center;
}

.screen-division-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}
.screen-division-container .screen-division {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 45vw;
  height: 70vh;
}
.screen-division-container .left-container {
  margin-left: 5vw;
}
.screen-division-container .left-container .text-editor-for-memorizing {
  font-family: "corp";
  font-size: 60px;
  color: #66d17f;
}
.screen-division-container .left-container .auth-button-container {
  padding: 5vh 0;
}
.screen-division-container .left-container .auth-button-container div {
  margin-top: 5vh;
}
.screen-division-container .left-container .auth-button-container .term-container {
  margin-top: 1vh;
}
.screen-division-container .right-container {
  margin-right: 5vw;
}

@media screen and (max-width: 700px) {
  .screen-division-container {
    display: block;
  }
  .screen-division-container .screen-division {
    width: 100vw;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .screen-division-container .left-container {
    padding-top: 100px;
  }
  .screen-division-container .left-container .text-editor-for-memorizing {
    font-size: 50px;
  }
}
@media screen and (max-width: 700px) and (min-aspect-ratio: 5/9) {
  .screen-division-container .left-container .text-editor-for-memorizing {
    font-size: 40px;
  }
  .screen-division-container .left-container .auth-button-container {
    padding: 0;
  }
}
@media screen and (max-width: 700px) and (min-aspect-ratio: 5/7) {
  .screen-division-container .left-container {
    padding-top: 80px;
  }
}</style>
