<script lang="ts">import { push } from "svelte-spa-router";
import firebase from "firebase/app";
import "firebase/auth";
import { downloadInsertHTML } from "./fileButtonGroup/Download.svelte";
import { overwriteInsertHTML, writeToLocal, writeToSlouch } from "./fileButtonGroup/Overwrite.svelte";
import { newFileInsertHTML, openBrandNew, openLocalFile } from "./fileButtonGroup/NewFile.svelte";
import { myNoteInsertHTML } from "./fileButtonGroup/MyNote.svelte";
import { getEditorPreviewDOM } from "../utils/getEditorPreviewDom.svelte";
const signOut = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
        push("/signin");
    });
};
const toggleToolCard = (icon) => {
    const toolCard = document.getElementById("tool_card");
    if (!toolCard)
        throw new Error("Tool card doesn't exist");
    toolCard.classList.add("visible");
    toolCard.style.zIndex = "7";
    const toBeInserted = document.getElementById("to_be_inserted");
    if (!toBeInserted)
        throw new Error("toBeInserted div doesn't exist");
    if (icon === "download")
        downloadInsertHTML(toBeInserted);
    if (icon === "save")
        overwriteInsertHTML(toBeInserted);
    if (icon === "plus")
        newFileInsertHTML(toBeInserted);
    if (icon === "user-circle")
        myNoteInsertHTML(toBeInserted);
};
// 現在は使われてない
const jumpToHelp = () => {
    window.open("#/help");
};
const fileButtonArray = [
    { icon: "user-circle", words: "マイノート", onclick: toggleToolCard },
    { icon: "plus", words: "新規作成", onclick: toggleToolCard },
    { icon: "save", words: "上書き保存", onclick: toggleToolCard },
    { icon: "download", words: "ダウンロード", onclick: toggleToolCard },
    // { icon: "question", words: "ヘルプ", onclick: jumpToHelp },
    { icon: "sign-out-alt", words: "サインアウト", onclick: signOut },
];
// ショートカットキーを割り当てる
window.addEventListener("keydown", (event) => {
    // 上書き
    if (event.key === "s" && event.metaKey) {
        event.preventDefault();
        // シフトも押されてたらローカルファイルを上書き
        if (event.shiftKey) {
            writeToLocal();
            return;
        }
        const dataObj = getEditorPreviewDOM.getAllAsObj();
        writeToSlouch(dataObj);
    }
    // 新規ノート作成
    if (event.key === "b" && event.metaKey) {
        event.preventDefault();
        openBrandNew();
    }
    // ローカルファイルを読み込む
    if (event.key === "i" && event.metaKey) {
        event.preventDefault();
        openLocalFile();
    }
});
</script>

<div class="file-button-container">
  {#each fileButtonArray as { icon, words, onclick }, i}
    <button
      class="file-button"
      id="{icon}"
      on:click="{() => {
        onclick(icon);
      }}"
    >
      <span class="fas fa-{icon} file-button-icon"></span>
      <span class="file-button-words">{words}</span>
    </button>
  {/each}
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.file-button-container {
  margin: 0 auto;
  text-align: center;
}

.file-button-container {
  width: 100%;
  height: 100%;
}
.file-button-container .file-button {
  text-align: left;
  width: 80%;
  padding: 5% 0 5% 10%;
  border-bottom: 4px #f5f5f5 solid;
  transition: 0.1s;
}
.file-button-container .file-button-icon {
  color: #66d17f;
  font-size: 25px;
  display: inline-flex;
  vertical-align: middle;
  padding: 0px 10px;
}
.file-button-container .file-button-words {
  color: #46585c;
  font-family: "nsjp";
  font-weight: bold;
  font-size: 18px;
  display: inline-flex;
  vertical-align: middle;
}
.file-button-container .file-button:hover {
  background: #f5f5f5;
  transition: 0.1s;
}

@media screen and (max-width: 1200px) {
  .file-button-container .file-button {
    padding: 0 0 0 5%;
    height: 6.5vh;
  }
  .file-button-container .file-button-icon {
    font-size: 19px;
  }
  .file-button-container .file-button-words {
    font-size: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .file-button-container .file-button {
    height: 7vh;
    padding: 0 0 0 3%;
  }
  .file-button-container .file-button-icon {
    font-size: 15px;
  }
  .file-button-container .file-button-words {
    font-size: 10px;
  }
}
@media (max-aspect-ratio: 1/1) {
  .file-button-container .file-button {
    height: 7vh;
    padding: 0 0 0 1%;
  }
  .file-button-container .file-button-icon {
    font-size: 18px;
  }
  .file-button-container .file-button-words {
    font-size: 13px;
  }
}</style>
