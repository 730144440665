<script lang="ts">import { ParsingMD } from "./CenterNote.svelte";
import { onMount } from "svelte";
class EditorButtons {
    constructor(editElement, previewElement) {
        this.editButton = editElement;
        this.previewButton = previewElement;
        console.log("--- Got previewButton and EditorButton ---");
    }
    changeAppearanceToEditor() {
        triggerAntinomyButtons(this.editButton, this.previewButton);
        ParsingMD.changeAppearance(0);
    }
    changeAppearanceToPreview() {
        triggerAntinomyButtons(this.previewButton, this.editButton);
        ParsingMD.parseElement();
        ParsingMD.changeAppearance(1);
    }
    emulateClicking() {
        if (this.editButton.hasAttribute("disabled"))
            return this.previewButton.click();
        return this.editButton.click();
    }
}
// クラスのインスタンスをグローバルに生成する
let editorButtonsGlobal;
onMount(() => {
    const editElement = document.getElementById("Editor");
    if (!editElement)
        throw new Error("editButton is null");
    const previewElement = document.getElementById("Preview");
    if (!previewElement)
        throw new Error("previewButton is null.");
    editorButtonsGlobal = new EditorButtons(editElement, previewElement);
});
const editorButtonOnclick = () => {
    editorButtonsGlobal.changeAppearanceToEditor();
};
const previewButtonOnclick = () => {
    editorButtonsGlobal.changeAppearanceToPreview();
};
// 押されたボタンをdisabledにして他方のdisabledを削除する
const triggerAntinomyButtons = (clicked, another) => {
    clicked.setAttribute("disabled", "true");
    // 押されたボタンのIDを取得してタイトルにぶち込む
    const idName = clicked.getAttribute("id");
    if (!idName)
        throw new Error("idName is null");
    document.title = `SLOUCH - ${idName}`;
    // 他方のボタンがdisabledだったらそれを削除する
    if (another.hasAttribute("disabled"))
        another.removeAttribute("disabled");
};
// エディターとプレビューを切り替えるキーボードショートカット
window.addEventListener("keydown", (event) => {
    if (event.key === "e" && event.ctrlKey) {
        editorButtonsGlobal.emulateClicking();
    }
});
</script>

<svelte:head>
  <title>SLOUCH - Editor</title>
</svelte:head>

<div class="editor-button-group">
  <button class="editor-button" id="Preview" data-tooltip="Ctrl + E" on:click="{previewButtonOnclick}"><i
      class="fas fa-eye"
    ></i></button>
  <button class="editor-button" id="Editor" data-tooltip="Ctrl + E" on:click="{editorButtonOnclick}" disabled><i
      class="fas fa-pencil-alt"
    ></i></button>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.editor-button-group {
  margin: 0 auto;
  text-align: center;
}

.editor-button-group {
  padding-top: 30px;
}
.editor-button-group .editor-button {
  margin: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px #66d17f solid;
  color: #66d17f;
  font-size: 18px;
  transition: 0.2s;
  position: relative;
}
.editor-button-group .editor-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.editor-button-group .editor-button:disabled {
  background-color: #66d17f;
  color: #f5f5f5;
  transition: 0.2s;
}

@media (max-aspect-ratio: 1/1) {
  .editor-button-group {
    padding: 10px 0;
  }
  .editor-button-group .editor-button {
    margin: 0 10px;
  }
}</style>
