<script lang="ts">import { link } from "svelte-spa-router";
</script>

<div class="rightcard-copyright">
  <div class="terms">
    <a href="/privacy-policy" use:link>プライバシーポリシー</a>
    ・<a href="/term-of-use" use:link>利用規約</a>
  </div>
  <div>© 2021 Kota Yatagai</div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.rightcard-copyright {
  margin: 0 auto;
  text-align: center;
}

.rightcard-copyright {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
.rightcard-copyright div {
  color: #66d17f;
  font-weight: bold;
  font-size: 15px;
}
.rightcard-copyright .terms {
  font-size: 10px;
  margin-bottom: 5px;
}</style>
