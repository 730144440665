<script lang="ts" context="module">export const fireToast = (message, warning = "") => {
    const toast = document.getElementById("toast");
    if (!toast)
        throw new Error("toast doesn't exist");
    const toastWords = document.getElementById("toast_words");
    if (!toastWords)
        throw new Error("toastWords doesn't exist");
    toastWords.innerText = message;
    if (warning === "warning")
        toast.style.backgroundColor = "#bf0000";
    if (warning === "message")
        toast.style.backgroundColor = "#aedb09";
    if (warning === "")
        toast.style.backgroundColor = "#66d17f";
    toast.classList.add("toast-visible");
    const removeClass = () => {
        toast.classList.remove("toast-visible");
    };
    setTimeout(removeClass, 2750);
};
</script>

<div class="toast" id="toast">
  <div class="toast-icon"><i class="fas fa-check"></i></div>
  <div class="toast-words" id="toast_words">正常に保存されました！</div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #66d17f;
  font-family: "nsjp";
  font-weight: bold;
  font-size: 15px;
  color: #fafafa;
  padding: 10px;
  box-shadow: 2px 2px 5px #ececec, -2px -2px 5px #f5f5f5;
  visibility: hidden;
  transition: 0.3s;
}
.toast-icon {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  font-size: 18px;
  border: 3px #fafafa solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}</style>
