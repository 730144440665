<script lang="ts">import { generateRandomNID, getDbRoot } from "../utils/dbUtils.svelte";
import { getEditorPreviewDOM } from "../utils/getEditorPreviewDom.svelte";
import { fireToast } from "./Toast.svelte";
export let titleValue = "無題のノート";
const saveTitle = async (titleValue) => {
    const dbRoot = await getDbRoot();
    const currentNote = dbRoot.current.data().current;
    if (!currentNote) {
        const randomNID = generateRandomNID();
        const dataObj = getEditorPreviewDOM.getAllAsObj();
        dbRoot.uRoot.set({
            current: randomNID,
            [randomNID]: dataObj,
        }, { merge: true });
        fireToast(`${dataObj.title}をはじめて保存しました`);
        return;
    }
    dbRoot.uRoot.update({
        [`${currentNote}.title`]: titleValue,
    });
    console.log("--- New title was saved ---");
    fireToast("タイトルが保存されました");
};
</script>

<div class="profile-stuff">
  <div id="title_input_container">
    <label>
      <input type="text" name="title" placeholder="ノートのタイトル" id="note_title" bind:value="{titleValue}" />
    </label>
    <button
      id="title_save"
      on:click="{() => {
        saveTitle(titleValue);
      }}"
    >保存</button>
  </div>
</div>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.profile-stuff {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px #f5f5f5 solid;
  margin: 0 auto;
  padding: 5%;
  max-height: 6.5vh;
  overflow-y: scroll;
  font-family: "inter", "nsjp";
}
.profile-stuff #title_input_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px #66d17f solid;
  border-radius: 5px;
}
.profile-stuff #title_input_container label {
  width: 70%;
}
.profile-stuff #title_input_container label input {
  width: calc(100% - 5px);
  padding-left: 5px;
  font-weight: bold;
  font-size: 15px;
  color: #46585c;
  word-break: keep-all;
  overflow-x: scroll;
}
.profile-stuff #title_input_container label input::placeholder {
  color: #85a6ad;
}
.profile-stuff #title_input_container #title_save {
  width: 30%;
  padding: 10px;
  background: #66d17f;
  color: #fafafa;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .profile-stuff {
    width: 90%;
  }
  .profile-stuff input {
    font-size: 13px;
  }
}</style>
