<script>
  import Logo from "../../assets/svgs/logoonly.svg"; // このSVG読込のエラーが消えなかったため、解決策が見つかるまでこのコンポーネントはTSではなくJSで書く
  export let width = window.innerWidth > window.innerHeight ? "150px" : "100px";
  export let height = window.innerWidth > window.innerHeight ? "48px" : "32px";
</script>

<header class="header-logo-container" aria-label="ヘッダー">
  <Logo width="{width}" height="{height}" />
</header>

<style lang="scss">@font-face {
  font-family: "corp";
  src: local("Corporate Logo Rounded"), url("../fonts/Corp/Corp.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Bold"), url("../fonts/NSJP-bold/NotoSansJP-Bold.woff") format("woff"), url("../fonts/NSJP-bold/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Medium"), url("../fonts/NSJP-medium/NotoSansJP-Medium.woff") format("woff"), url("../fonts/NSJP-medium/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "nsjp";
  src: local("Noto Sans JP Regular"), url("../fonts/NSJP-regular/NotoSansJP-Regular.woff") format("woff"), url("../fonts/NSJP-regular/NotoSansJP-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Bold"), url("../fonts/Dosis-bold/Dosis-Bold.woff") format("woff"), url("../fonts/Dosis-bold/Dosis-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "dosis";
  src: local("Dosis Regular"), url("../fonts/Dosis-regular/Dosis-Regular.woff") format("woff"), url("../fonts/Dosis-regular/Dosis-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Bold"), url("../fonts/Inter-bold/Inter-Bold") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "inter";
  src: local("Inter Regular"), url("../fonts/Inter-regular/Inter-Regular") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Cica";
  src: local("Cica Regular"), url("../fonts/Cica-regular/Cica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
.header-logo-container {
  margin: 0 auto;
  text-align: center;
}

.header-logo-container {
  position: fixed;
  padding: 20px 0px 0px 20px;
}

@media (max-aspect-ratio: 1/1) {
  .header-logo-container {
    position: relative;
    padding: 20px 0;
  }
}</style>
